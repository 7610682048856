import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import {
  HeroBlock,
  FeaturedItemBlock,
  Testimonial,
  Decoration,
  Space,
  SEO,
} from '../components/shared'

import { CollectionSlider } from '../components/shared/sliders'
import { mobileVw, desktopBreakpoint, desktopVw, colors } from '../styles'
import { ContentBlock } from '../components/home'
import VideoSchema from '../components/shared/VideoSchema'

const BoutiqueLanding = ({
  location,
  data: { contentfulPageBoutiqueLanding, contentfulGlobalSite },
}) => {
  const {
    heroblock,
    collectionSlider,
    contentblock,
    seoDescription,
    seoTitle,
  } = contentfulPageBoutiqueLanding

  const {
    blackBrushesAllRecipePages,
    classicProductPageTestimonialDecoration,
    classsicLandingPageBottomDecoration,
    currentSeason,
    defaultVideoTestimonial,
  } = contentfulGlobalSite

  return (
    <BoutiqueLandingPageWrapper>
      <SEO
        description={seoDescription}
        title={seoTitle}
        location={location}
        pageType='Boutique'
      />
      <VideoSchema 
        schema={{
          "@context": "http://schema.org/",
          "@type": "VideoObject",
          'name': seoTitle && seoTitle,
          'description': seoDescription && seoDescription,
          "uploadDate": "2020-07-19",
          "thumbnailUrl": defaultVideoTestimonial.poster.fluid.src,
          "contentUrl": defaultVideoTestimonial.videoMp4.file.url
        }}
      />
      <Background>
        <HeroBlock
          marginTitle={[41, 10]}
          gold
          data={heroblock}
          zIndex={2}
          fontSize={70}
        />
        {
          collectionSlider &&
          <Wrapper>
            <CollectionSlider
              titleHeading={collectionSlider.title}
              slides={collectionSlider.slides}
            />
          </Wrapper>
        }
        <StyledDecoration>
          {blackBrushesAllRecipePages &&
            blackBrushesAllRecipePages.map((item, i) => {
              return <Decoration key={i} data={item} />
            })}
        </StyledDecoration>
      </Background>
      {/* <Space l={4} s={3} /> */}
      <div>
        <Testimonial
          defaultImage={defaultVideoTestimonial}
          decoration={classicProductPageTestimonialDecoration}
          classicalVersion
        />
        <Space l={4} s={1} />
      </div>
      <StyledFeaturedItemBlock>
        <FeaturedItemBlock
          title={['La recette', 'du mois']}
          id={100}
          season={currentSeason}
          hideRating
          classicalPage
        />
      </StyledFeaturedItemBlock>
      <Space l={0} s={1} />
      <Background>
        <StyledContent>
          <ContentBlock
            type='full'
            title={contentblock.title}
            decoration={contentblock.decorations}
            image={contentblock.image}
            description={contentblock.description.description}
            button={contentblock.buttonText}
            layout={contentblock.flexLayout}
            buttonSlug={contentblock.buttonText.link.slug}
          />
        </StyledContent>
        <Space l={0} s={3} />
        <StyledBanner>
          {classsicLandingPageBottomDecoration.map((data, i) => {
            return <Decoration key={i} data={data} />
          })}
        </StyledBanner>
      </Background>
    </BoutiqueLandingPageWrapper>
  )
}

const Background = styled.div`
  background: #000;
`

const Wrapper = styled.div`
  button {
    color: white;
  }

  h2 {
    color: ${colors.white};
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: ${desktopVw(-30)};
  }
`

const BoutiqueLandingPageWrapper = styled.div`
  overflow-x: hidden;
`

const StyledFeaturedItemBlock = styled.div`
  margin: 0 ${mobileVw(16)} ${mobileVw(50)};

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 ${desktopVw(260)} ${desktopVw(110)};
    .featured-image {
      margin-top: 0;
      width: 100%;
      max-height: ${desktopVw(450)};
      img {
        object-position: center center !important;
      }
    }
  }
`

const StyledContent = styled.div`
  padding: 0 0 ${mobileVw(50)};

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 0 ${desktopVw(400)};
  }
`

const StyledDecoration = styled.div`
  position: relative;
  background: #000;
  margin-bottom: ${desktopVw(50)};
  padding-top: ${mobileVw(40)};
  margin-bottom: ${mobileVw(15)};

  @media (min-width: ${desktopBreakpoint}) {
    padding-top: ${desktopVw(65)};
    margin-bottom: ${desktopVw(50)};
  }
`

const StyledBanner = styled.div`
  position: relative;
  background: black;
`

export const BoutiqueLandingQuery = graphql`
  query BoutiqueLandingQuery($id: String!, $node_locale: String!) {
    contentfulPageBoutiqueLanding(id: { eq: $id }) {
      seoDescription
      seoTitle
      heroblock {
        marginDescription
        title
        richDescription {
            raw
        }
        marginBottom
        background
        breadcrumbs {
          title
          link {
            ...linkTarget
          }
        }
        imagesSlider {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        decoration {
          image {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          positionDesktop
          positionMobile
          widthDesktop
          transformBlock
          widthMobile
          view
          name
        }
      }
      collectionSlider {
        title
        slides {
          ... on ContentfulPageEBoutiqueCollection {
            title
            slug
            thumbnailImage {
              title
              fluid(maxWidth: 1800) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      contentblock {
        flexLayout
        title
        description {
          description
        }
        buttonText {
          title
          link {
            ... on ContentfulPageEBoutique {
              id
              slug
            }
          }
        }
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        decorations {
          image {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          positionDesktop
          positionMobile
          widthDesktop
          transformBlock
          widthMobile
          view
          name
        }
      }
    }
    contentfulGlobalSite(node_locale: { eq: $node_locale }) {
      defaultVideoTestimonial {
        name
        poster {
          fluid {
            src
          }
        }
        videoMov {
          file {
            url
          }
        }
        videoMp4 {
          file {
            url
          }
        }
        videoTrackFile {
            file {
              fileName
              url
            }
          }
      }
      currentSeason
      classsicLandingPageBottomDecoration {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      classicProductPageTestimonialDecoration {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      blackBrushesAllRecipePages {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
    }
  }
`

export default BoutiqueLanding
